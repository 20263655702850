import { Link } from "react-router-dom";
import logo2023 from "../imagenes/imglayouts/imgheader/logo2023.png"
import portal from "../imagenes/imglayouts/imgheader/portal2023.png"
import "../estilos/layouts/header.css";
const Header=()=>{
   return(
     <div className="head">
         <div className="headers">
            <div className="brand">
              <Link    to="/"> <img src={logo2023} alt="" width={330} /></Link>
            </div>
            
           <div>
             <a href="https://www.transparencia.gob.pe/enlaces/pte_transparencia_enlaces.aspx?id_entidad=67965" target="_blank" rel="noopener noreferrer">
             <img src={portal} alt="pte" width={100} />
            </a>
           </div>

       </div>
       {/*<div className="headertext">
        {<a href="https://www.muniurcos.gob.pe/static/media/decretoalcaldia_01_2024_100424.338d471a2a91ac5750ca.pdf" target="_blank" rel="noopener noreferrer">
        <h5 className="headertext">Decreto de Alcaldía N° 01-2024-MPQ/U. "Audiencia pública de rendición de cuentas de la Municipalidad Provincial de Quispicanchi"</h5>
        </a>}
        
      </div>
      <div className="headertext2">
        <h6>Fecha: domingo, 19 de mayo 2024. Hora: 9:00 a.m.
        Lugar: Plaza de Armas del distrito de Urcos.</h6>
      </div>*/}
      
   </div>
   );
}
export default Header;