import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate, HashRouter} from 'react-router-dom';
/*importamos componentes*/
import Inicio from './components/inicio.jsx';
import Ubicacion from './components/ubicacion';
import MisionVision from './components/misionvision.jsx';
import Regidores from './components/regidores.jsx';
import Comisiones from './components/comisiones';
import MesaPartes from './components/mesapartes';
import RegistroCivil from './components/registrocivil.jsx';
import Transito from './components/transito.jsx';
import InstrumentosGestion from './components/instrumentosgestion';
import ResolucionAlcaldia from './components/resolucionalcaldia';
import DecretosAlcaldia from './components/decretosalcaldia';
import ResolucionGerencia from './components/resoluciongerencia';
import DirectivasGerencia from './components/directivasgerencia';

import Funcionarios from './components/funcionarios.jsx';
import Festividades from './components/festividades';
import DeclaracionesJuradas from './components/declaracionesjuradas.jsx';
import Contacto from './components/contacto.jsx'
import Convocatoria from './components/convocatoria.jsx'
import OrdenanzaMunicipal from './components/ordenanzamunicipal';

/*importamos layouts*/
import Menu from './layouts/menu.jsx';
import Header from './layouts/header.jsx';
import Footer from './layouts/footer.jsx';
/*Transparencia*/
import ActividadesOficiales from './components/transparencia/actividadesoficiales.jsx';
import ParticipacionCiudadana from './components/transparencia/participacionciudadana';
import PresupuestoParticipativo from './components/transparencia/presupuestoparticipativo';
import RendicionCuentas from './components/transparencia/rendicioncuentas';

function App() {
  return (
    <div className="App">
      <HashRouter>
      <header><Header /></header>
        <Routes>
          <Route path='/' element={<Menu/>} >
            <Route index element={<Inicio/>} />
            <Route path='ubicacion' element={<Ubicacion/>} /> 
            <Route path='festividades' element={<Festividades/>} /> 
            <Route path='misionvision' element={<MisionVision/>} />
            <Route path='regidores' element={<Regidores/>} /> 
            <Route path='comisiones' element={<Comisiones/>} /> 
            <Route path='mesapartes' element={<MesaPartes/>} /> 
            <Route path='registrocivil' element={<RegistroCivil/>} />
            <Route path='transito' element={<Transito/>} />
            <Route path='instrumentosgestion' element={<InstrumentosGestion/>} /> 
            <Route path='resolucionalcaldia' element={<ResolucionAlcaldia/>} /> 
            <Route path='decretosalcaldia' element={<DecretosAlcaldia/>} />
            <Route path='resoluciongerencia' element={<ResolucionGerencia/>} />
            <Route path='directivasgerencia' element={<DirectivasGerencia/>} />
            <Route path='ordenanzamunicipal' element={<OrdenanzaMunicipal/>} />
            <Route path='funcionarios' element={<Funcionarios/>} /> 
            <Route path='declaracionesjuradas' element={<DeclaracionesJuradas/>} /> 
            <Route path='convocatoria' element={<Convocatoria/>} />
            <Route path='contacto' element={<Contacto/>} /> 

            <Route path='presupuestoparticipativo' element={<PresupuestoParticipativo/>} /> 
            <Route path='actividadesoficiales' element={<ActividadesOficiales/>} /> 
            <Route path='participacionciudadana' element={<ParticipacionCiudadana/>} /> 
            <Route path='rendicioncuentas' element={<RendicionCuentas/>} /> 

            <Route path='*' element={ <Navigate replace to="/" />} />
          </Route>
        </Routes>
        <footer><Footer/></footer>   
      </HashRouter>
    </div>
  );
}

export default App;
